import React, {Component} from 'react'
import Icon from 'components/icon'
import Pagination from 'components/pagination/'
import Results from './results'
// import Select from 'components/select/'
import Select from 'react-select'
import style from './search.module.scss';
import TextInput from 'shared/form/textinput/';

const GENDERS = ['All', 'Male', 'Female'];
const SEARCH_URL = `${process.env.GATSBY_ZMS_API_URL}search/all/?model=provider&_page=1&_pageSize=200`;

class ProviderSearch extends Component {
	constructor(props) {
		super(props);
		this.resultsRef = React.createRef();
		this.specialties = [];
		this.state = {
			terms: '',
			newPatientFilter: false,
			childrenFilter: false,
			providers: this.props.data,
			allProviders: this.props.data,
			currentProviders: [],
			currentPage: null,
			totalPages: null,
			offset: 0,
			resultsScroll: null,
			checkboxes: this.specialties.reduce(
				(specialties, specialty) => ({
					...specialties,
					[specialty]: false
				}),
				{}
			),
			genders: 'All',
			filterToggle: true,
			locationOptions: [],
			locationFilter: null,
			isSearchLoading: false,
			waitingToSearch: false,
			page: 1,
			showValue: true,
			isKeywordSearch: false,
			keywordSearchResults: [],
		};
		this.toggleClick = this.toggleClick.bind(this);
		this.childrenToggleClick = this.childrenToggleClick.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.clearFilters = this.clearFilters.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.handleGenderChange = this.handleGenderChange.bind(this);
		this.filterToggle = this.filterToggle.bind(this);
		this.onPageChanged = this.onPageChanged.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.keywordSearch = this.keywordSearch.bind(this);
		this.removeDuplicateObjectFromArray = this.removeDuplicateObjectFromArray.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.keyDownHandler = this.keyDownHandler.bind(this);
		this.timerID = null;
	}

	handleSearch(evt) {
		this.setState({
			terms: evt.target.value,
			isSearchLoading: true,
			waitingToSearch: true,
		});
	}

	keyDownHandler(evt) {
		if (evt.key === 'Enter') {
			this.keywordSearch();
		}
	}

	keywordSearch() {
		this.setState({
			isSearchLoading: true,
			waitingToSearch: false,
			isKeywordSearch: true,
		});
		const terms = this.state.terms;
		if (terms !== '') {
			fetch(`${SEARCH_URL}&terms=${terms}`)
				.then((response) => {
					return response.json();
				})
				.then((json) => {
					let ids = [];
					let keywordProviders = [];
					json.data.forEach(item => {
						ids.push(item.id);
					})
					this.state.allProviderData.forEach(provider => {
						if (ids.includes(provider.id)) {
							keywordProviders.push(provider);
						}
					});
					this.setState({
						isSearchLoading: false,
						allProviders: keywordProviders,
						keywordSearchResults: keywordProviders,
						newPatientFilter: false,
						childrenFilter: false,
						locationFilter: '',
						genders: 'All',
						checkboxes: this.specialties.reduce(
							(specialties, specialty) => ({
								...specialties,
								[specialty]: false
							}),
							{}
						),
					});
					if (this.state.resultsScroll === null) {
						this.setState({
							resultsScroll: true,
						})
					} else if (this.state.resultsScroll === true) {
						let scrollOffset = this.resultsRef.current.offsetTop-60;
						window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
					}
				})
				.catch((err) => {
					console.log(err);
				})
			;
		}
		else {
			this.setState({
				isSearchLoading: false,
				allProviders: this.state.allProviderData,
				keywordSearchResults: [],
				newPatientFilter: false,
				childrenFilter: false,
				locationFilter: '',
				genders: 'All',
				checkboxes: this.specialties.reduce(
					(specialties, specialty) => ({
						...specialties,
						[specialty]: false
					}),
					{}
				),
			});
		}
	}

	removeDuplicateObjectFromArray(array, key) {
		return array.filter((obj, index, self) =>
			index === self.findIndex((el) => (
				el[key] === obj[key]
			))
		)
	}

	doSearch() {
		let query = this.state.query;
		let providers = [];

		let queryData = this.state.allProviderData;
		if (this.state.isKeywordSearch) {
			queryData = this.state.keywordSearchResults;
		}

		switch (this.state.genders) {
			case 'Female':
				queryData.forEach(provider => {
					if (provider.gender === 'Female') {
						providers.push(provider);
					}
				});
				break;
			case 'Male':
				queryData.forEach(provider => {
					if (provider.gender === 'Male') {
						providers.push(provider);
					}
				});
				break;
			default:
				providers = queryData;
				break;
		}
		if (this.state.newPatientFilter) {
			let newPatientProviders = [];
			if (providers.length) {
				providers.forEach(provider => {
					if (provider.acceptingNewPatients) {
						newPatientProviders.push(provider);
					}
				});
			} else {
				queryData.forEach(provider => {
					if (provider.acceptingNewPatients) {
						newPatientProviders.push(provider);
					}
				});
			}
			providers = newPatientProviders;
		}
		if (this.state.childrenFilter) {
			let childProviders = [];
			if (providers.length) {
				providers.forEach(provider => {
					if (provider.patientsAccepted.includes('Children')) {
						childProviders.push(provider);
					}
				});
			} else {
				queryData.forEach(provider => {
					if (provider.patientsAccepted.includes('Children')) {
						childProviders.push(provider);
					}
				});
			}
			providers = childProviders;
		}
		if (this.state.locationFilter && this.state.locationFilter !== '') {
			let locationProviders = [];
			if (providers.length) {
				providers.forEach(provider => {
					if (provider.locations.length) {
						provider.locations.forEach(location => {
							if (location.id === this.state.locationFilter) {
								locationProviders.push(provider);
							}
						});
					}
				});
			} else {
				queryData.forEach(provider => {
					if (provider.locations.length) {
						provider.locations.forEach(location => {
							if (location.id === this.state.locationFilter) {
								locationProviders.push(provider);
							}
						});
					}
				});
			}
			providers = locationProviders;
		}
		let emptyBoxes = true;
		for (const box in this.state.checkboxes) {
			if (this.state.checkboxes[box]) {
				emptyBoxes = false;
			}
		}
		if (!emptyBoxes) {
			let specialtyProviders = [];
			for (const box in this.state.checkboxes) {
				if (this.state.checkboxes[box]) {
					if (providers.length) {
						providers.forEach(provider => {
							if (provider.specialty.length) {
								provider.specialty.forEach(item => {
									if (item === box) {
										specialtyProviders.push(provider);
									}
								});
							}
						});
					} else {
						queryData.forEach(provider => {
							if (provider.specialty.length) {
								provider.specialty.forEach(item => {
									if (item === box) {
										specialtyProviders.push(provider);
									}
								});
							}
						});
					}
				}
			}
			providers = specialtyProviders;
		} else {
			providers = providers;
		}
		providers = this.removeDuplicateObjectFromArray(providers, 'id');
		this.setState({
			allProviders: providers,
			currentPage: 1,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.genders !== prevState.genders) {
			this.doSearch();
		}
		if (this.state.newPatientFilter !== prevState.newPatientFilter) {
			this.doSearch();
		}
		if (this.state.childrenFilter !== prevState.childrenFilter) {
			this.doSearch();
		}
		if (this.state.locationFilter !== prevState.locationFilter) {
			this.doSearch();
		}
		for (const box in this.state.checkboxes) {
			if (this.state.checkboxes[box] !== prevState.checkboxes[box]) {
				this.doSearch();
			}
		}
	}

	onPageChanged = data => {
		const { allProviders } = this.state;
		const { currentPage, totalPages, pageLimit } = data;

		const offset = (currentPage - 1) * pageLimit;
		const currentProviders = allProviders.slice(offset, offset + pageLimit);
		this.setState({ currentPage, currentProviders, totalPages, offset });
		if (this.state.resultsScroll === null) {
			this.setState({
				resultsScroll: true,
			})
		} else if (this.state.resultsScroll === true) {
			let scrollOffset = this.resultsRef.current.offsetTop-60;
			window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
		}
	}

	toggleClick(evt) {
		this.setState(prevState => ({
			newPatientFilter: !prevState.newPatientFilter,
		}));
	}

	childrenToggleClick(evt) {
		this.setState(prevState => ({
			childrenFilter: !prevState.childrenFilter,
		}));
	}

	filterToggle() {
		this.setState(prevState => ({
			filterToggle: !prevState.filterToggle,
		}));
	}

	handleCheckboxChange(event) {
		event.preventDefault();
		event.persist();
		let name = event.currentTarget.dataset.name;
		this.setState(prevState => ({
			checkboxes: {
				...prevState.checkboxes,
				[name]: !prevState.checkboxes[name]
			}
		}));
	};

	clearFilters(event) {
		this.setState({
			newPatientFilter: false,
			childrenFilter: false,
			locationFilter: null,
			genders: 'All',
			allProviders: this.state.allProviderData,
			keywordSearchResults: [],
			page: 1,
			terms: '',
			isKeywordSearch: false,
			checkboxes: this.specialties.reduce(
				(specialties, specialty) => ({
					...specialties,
					[specialty]: false
				}),
				{}
			),
			showValue: false,
		});
		const pageLimit = 6;
		const currentPage = 1;
		const offset = (currentPage - 1) * pageLimit;
		const currentProviders = this.state.allProviderData.slice(offset, offset + pageLimit);
		this.setState({ currentPage, currentProviders, offset});
		if (this.state.resultsScroll === null) {
			this.setState({
				resultsScroll: true,
			})
		} else if (this.state.resultsScroll === true) {
			let scrollOffset = this.resultsRef.current.offsetTop-60;
			window.scroll({top: scrollOffset, left: 0, behavior: 'smooth' });
		}
	}

	handleGenderChange(event) {
		event.preventDefault();
		event.persist();
		let name = event.currentTarget.dataset.name;
		this.setState({
			genders: name,
		})
	}

	changeHandler(evt) {
		if (evt) {
			this.setState({
				locationFilter: evt.value,
				showValue: true,
			})
		} else {
			this.setState({
				locationFilter: null,
			})
		}
	}

	componentDidMount() {
		const allProviders = this.props.data;

		// Get available location options from providers
		let locationOptions = [];
		allProviders.forEach(provider => {
			if (provider.locations) {
				provider.locations.forEach(location => {
					let options = {
						value: location.id,
						label: location.name,
					};
					locationOptions.push(options);
				});
			}
		});
		if (locationOptions.length > 0) {
			locationOptions = this.removeDuplicateObjectFromArray(locationOptions, 'value');
		}
		locationOptions.sort((a, b) => {
			let fa = a.label.toLowerCase(),
				fb = b.label.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});

		// Get available specialties from providers
		let allSpecialties = [];
		allProviders.forEach(provider => {
			if (provider.specialty) {
				provider.specialty.forEach(item => {
					allSpecialties.push(item);
				});
			}
		});
		allSpecialties = [...new Set(allSpecialties)];
		allSpecialties.sort();
		this.specialties = allSpecialties;

		// Setting state of providers and locations options
		this.setState({ 
			allProviders: allProviders, 
			allProviderData: allProviders,
			locationOptions: locationOptions,
		});
		if (this.props.specialty !== null) {
			this.setState({
				currentProviders: [],
				checkboxes: this.specialties.reduce(
					(specialties, specialty) => ({
						...specialties,
						[specialty]: (this.props.specialty === specialty) ? true : false
					}),
					{}
				)
			})
		} else {
			this.setState({ 
				checkboxes: this.specialties.reduce(
					(specialties, specialty) => ({
						...specialties,
						[specialty]: false
					}),
					{}
				),
			});
		}
		if (this.props.locationID) {
			this.setState({
				currentProviders: [],
				locationFilter: this.props.locationID,
			})
		}
	}

	render() {
		var { allProviders, currentProviders, currentPage, totalPages } = this.state;
		var totalProviders = this.state.allProviders.length;
		// if (totalProviders === 0) return null;
		const locationFilter = {
			name: 'location',
			placeholder: 'Select',
			label: 'Location',
			options: this.state.locationOptions,
		}
		// console.log('========= components/providers/search.js =========');
		// console.log(this.props.specialty);
		// console.log(this.state.checkboxes);
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className={[style.filtersWrapper, "cell medium-4"].join(' ')}>
						<div className={[style.filtersHeader, "grid-x"].join(' ')}>
							<div className="cell shrink hide-for-medium">
								<button onClick={this.filterToggle} className={style.filterToggle}>
									<Icon icon={this.state.filterToggle ? 'downChevron' : 'upChevron'} />
								</button>
							</div>
							<div onClick={this.filterToggle} className="cell auto"><h3>Filter Results</h3></div>
							<div className="cell shrink"><div onClick={this.clearFilters} className={style.clear}>Clear Filters</div></div>
						</div>
						<div className={[style.searchFilters, (this.state.filterToggle ? style.collapsed : style.expanded)].join(' ')}>
							<div className={[style.kewordSearch, "grid-x"].join(' ')}>
								<div className="cell auto"><TextInput className={style.searchInput} autoComplete={false} label="Search by keyword" name="terms" value={this.state.terms} onChange={this.handleSearch} onKeyDown={this.keyDownHandler} /></div>
								<div className={[style.searchButtonContainer, "cell shrink"].join(' ')}>
									<div role="presentation" className={style.searchButton} onClick={this.keywordSearch}>Search</div>
								</div>
							</div>
							<div role="presentation" aria-label="Accepting New Patients" onClick={this.toggleClick} className={[style.newPatientFilter, "grid-x"].join(' ')}>
								<div className="cell auto">Accepting New Patients</div>
								<div className="cell shrink">
									<div className={[style.toggleFilter, `${(this.state.newPatientFilter) ? style.on : ''}`].join(' ')}>
										<span className={style.ball}></span>
									</div>
								</div>
							</div>
							<div role="presentation" aria-label="Sees Children" onClick={this.childrenToggleClick} className={[style.newPatientFilter, "grid-x"].join(' ')}>
								<div className="cell auto">Sees Children<br/><small>(Ages 17 and below)</small></div>
								<div className="cell shrink">
									<div className={[style.toggleFilter, `${(this.state.childrenFilter) ? style.on : ''}`].join(' ')}>
										<span className={style.ball}></span>
									</div>
								</div>
							</div>
							<div className={[style.specialtyFilters, "grid-x"].join(' ')}>
								<div className={[style.filterTitle, "cell small-12"].join(' ')}>Specialty</div>
								{this.specialties.map((specialty, index) => (
									<div key={index} data-name={specialty} onClick={this.handleCheckboxChange} className={[style.specialty, (this.state.checkboxes[specialty]) ? style.selected : '', "cell small-12"].join(' ')}><div className={style.specialtyBox}></div><div className={style.specialtyLabel}>{specialty}</div></div>
								))}
							</div>
							<div className={[style.locationFilters, "grid-x"].join(' ')}>
								<div className={[style.filterTitle, "cell small-12"].join(' ')}>Location</div>
								<Select 
									defaultValue={this.state.locationFilter} 
									className={style.selectContainer} 
									selectClassName={style.select} 
									onChange={this.changeHandler} 
									label={locationFilter.label} 
									placeholder={locationFilter.placeholder} 
									name={locationFilter.name} 
									options={locationFilter.options} 
									controlShouldRenderValue={this.state.showValue}
									styles={{
										control: (provided) => ({
											...provided,
											borderRadius: 0,
											borderColor: '#888B8D',
										})
									}}
								/>
							</div>
							<div className={[style.genderFilters, "grid-x"].join(' ')}>
								<div className={[style.filterTitle, "cell small-12"].join(' ')}>Provider Gender</div>
								<div className={[style.genderBlocks, "cell"].join(' ')}>
									{GENDERS.map((gender, index) => (
										<div key={index} data-name={gender} onClick={this.handleGenderChange} className={[style.gender, (this.state.genders === gender) ? style.active : ''].join(' ')}>{gender}</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className={[style.resultsWrapper, "cell medium-8"].join(' ')}>
						<div ref={this.resultsRef} className={[style.resultsNumber, "grid-x"].join(' ')}>
							<div className={[(totalProviders === 0) ? 'hide' : '', "cell text-right"].join(' ')}>{this.state.offset+1} - {(this.state.offset+6 > totalProviders) ? totalProviders : this.state.offset+6} / {(totalProviders > 0) ? `${totalProviders} results` : ''}</div>
						</div>
						<Results data={this.state.currentProviders} />
						<Pagination totalRecords={totalProviders} currentPage={currentPage} pageLimit={6} pageNeighbors={1} onPageChanged={this.onPageChanged} />
					</div>
				</div>
			</div>
		)
	}
}

export default ProviderSearch;