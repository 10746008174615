import React, {Component} from 'react'
import { StaticQuery, graphql } from 'gatsby'
// Import neccessary site components, try to maintain alphabetical order
import ProviderSearch from 'components/providers/search';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}


class ProviderSearchController extends Component {

	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/provider/all/?_join=Location`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let physicians = [];
					data.forEach(physician => {
						let sorted = false;
						this.allProviders.forEach(provider => {
							if (provider.id === physician.id) {
								physician.randSort = provider.randSort;
								sorted = true;
							}
						});
						if (!sorted) {
							physician.randSort = Math.floor(Math.random() * 10000);
						}
						physicians.push(this.processData(physician));
					});
					this.setState({
						status: STATUS.LOADED,
						data: this.shuffleArray(physicians),
					});
					console.log('Live Refresh Complete');
				}
			})
			.catch(err => console.log);
	}

	processData(data) {
		let newDatum = {
			id: data.id,
			firstName: data.firstName,
			lastName: data.lastName,
			maidenName: data.maidenName,
			middleName: data.middleName,
			nickName: data.nickName,
			title: data.abbreviations ? data.abbreviations.join(', ') : null,
			category: data.Category[0].object.name,
			photo: data.photo,
			link: data.uri,
			phone: data.directPhone,
			fax: data.fax,
			randSort: data.randSort,
			gender: data.gender,
			patientsAccepted: data.patientsAccepted,
			acceptingNewPatients: data.acceptingNewPatients,
			locations: [],
			specialty: [],
			specialtyIDs: [],
			specialtyExtra: data.specialtyExtra,
		}
		if (data.Specialty && data.Specialty.length) {
			let specialties = [];
			data.Specialty.forEach(specialty => {
				if (specialty.object.id) {
					this.specialties.forEach(item => {
						if (item.node.id === specialty.object.id) {
							specialties.push(item.node.name);
							newDatum.specialtyIDs.push(specialty.object.id);
						}
					});
				}
			});
			if (specialties.length) {
				specialties.sort();
				newDatum.specialty = specialties;
			} else {
				newDatum.specialty = [];
			}
		}
		if (data.Location && data.Location.length) {
			let locations = [];
			data.Location.forEach(location => {
				let locObject = {
					id: location.object.id,
					name: location.object.name,
					address: location.object.address1,
					city: location.object.city,
					state: location.object.state,
					zip: location.object.zip,
					phone: location.object.phone,
					fax: null,
					primary: location.properties.primary,
					uri: location.object.uri,
					hours: {},
					hoursNotes: null,
					sort: 0,
				}
				if (location.properties.hoursMon) { locObject.hours.Mo = location.properties.hoursMon };
				if (location.properties.hoursTue) { locObject.hours.Tu = location.properties.hoursTue };
				if (location.properties.hoursWed) { locObject.hours.We = location.properties.hoursWed };
				if (location.properties.hoursThu) { locObject.hours.Th = location.properties.hoursThu };
				if (location.properties.hoursFri) { locObject.hours.Fr = location.properties.hoursFri };
				if (location.properties.hoursSat) { locObject.hours.Sa = location.properties.hoursSat };
				if (location.properties.hoursSun) { locObject.hours.Su = location.properties.hoursSun };
				if (location.properties.hoursNotes) { locObject.hoursNotes = location.properties.hoursNotes };
				
				let physicianFloor = false;
				if (location.properties.floor) { 
					locObject.floor = location.properties.floor
					physicianFloor = true;
				};
				// Loop through locations to get floor/suite of each specialty
				if (location.object.id && !physicianFloor) {
					if (this.allLocations.length) {
						this.allLocations.forEach(item => {
							if (item.node.id === location.object.id) {
								if(item.node.sort !== undefined) {
									locObject.sort = item.node.sort;
								}
								if (item.node.Specialty.length) {
									item.node.Specialty.forEach(specialtyItem => {
										newDatum.specialtyIDs.forEach(id => {
											if (specialtyItem.object.id === id) {
												if (specialtyItem.properties) {
													if (specialtyItem.properties.floor && specialtyItem.properties.floor !== '') {
														locObject.floor = specialtyItem.properties.floor;
													}
												}
											}
										})
									})
								}
							}
						});
					}
				}
				locations.push(locObject);
			});
			if (locations.length) {
				locations.forEach(location => {
					if (location.primary) {
						location.sort = -1;
					}
				});
				locations.sort((a, b) => {
					let fa = a.sort,
						fb = b.sort;

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				});
				newDatum.locations = locations;
			} else {
				newDatum.locations = [];
			}
		}
		return newDatum;
	}

	constructor(props) {
		super(props);

		let initialData = [];
		this.specialties = (props.data && props.data.allPhysiciansSpecialty && props.data.allPhysiciansSpecialty.edges.length ? props.data.allPhysiciansSpecialty.edges : null);
		this.allLocations = (this.props.data.allLocationsLocation && this.props.data.allLocationsLocation.edges.length ? this.props.data.allLocationsLocation.edges : []);
		if (props.data && props.data.allPhysiciansProvider && props.data.allPhysiciansProvider.edges.length) {
			this.allProviders = props.data.allPhysiciansProvider.edges;
			props.data.allPhysiciansProvider.edges.forEach(physician => {
				initialData.push(this.processData(physician.node));
			});
		}

		this.state = {
			status: STATUS.INITIAL,
			data: this.shuffleArray(initialData),
		}
		this.liveRefresh = this.liveRefresh.bind(this);
		this.shuffleArray = this.shuffleArray.bind(this);
		this.processData = this.processData.bind(this);
	}

	shuffleArray(array) {
		// array.sort((left, right) => (left.randSort - right.randSort));
		array.sort((a, b) => {
			let fa = a.lastName.toLowerCase(),
				fb = b.lastName.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});
		return array;
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<ProviderSearch data={this.state.data} specialty={this.props.specialty} locationID={this.props.locationID} />
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			{
			  allPhysiciansProvider(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			      	id
			      	firstName
			      	lastName
			      	maidenName
			      	middleName
			      	nickName
			      	photo
			      	namePrefix
			      	abbreviations
			      	uri
			      	directPhone
			      	patientsAccepted
			      	randSort
			      	gender
			      	acceptingNewPatients
			      	specialtyExtra
			      	Category {
			      	  object {
			      	  	name
			      	  	id
			      	  }
			      	}
			      	Location {
			          object {
			          	id
			            name
			            address1
			            city
			            state
			            zip
			            uri
			            permalink
			          }
			          properties {
			          	primary
			            hoursMon
			            hoursTue
			            hoursWed
			            hoursThu
			            hoursFri
			            hoursSat
			            hoursSun
			            hoursNotes
			            floor
			          }
			        }
			        Specialty {
			          object {
			            id
			          }
			        }
			      }
			    }
			  }
			  allPhysiciansSpecialty(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			    edges {
			      node {
			        id
			        name
			      }
			    }
			  }
			  allLocationsLocation {
			    edges {
			      node {
			        id
			        name
			        Specialty {
			          properties {
			            floor
			            phone
			          }
			          object {
			            id
			            name
			          }
			        }
			      }
			    }
			  }
			}
		`}
		render={data => (<ProviderSearchController data={data} {...props} />)}
	/>
)