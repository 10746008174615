import React, {Component} from 'react'
import Background from 'shared/background';
import Button from 'components/button';
import Icon from 'components/icon';
import Link from 'shared/link';
import parse from 'html-react-parser';
import style from './searchCard.module.scss';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class SearchCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewAll: false,
			photo: (this.props.photo) ? this.props.photo : '/userfiles/dr-blank.jpg',
			data: this.props,
			status: STATUS.INITIAL,
			key: STATUS.INITIAL,
		};
		this.clickHandler = this.clickHandler.bind(this);
		this.liveRefresh = this.liveRefresh.bind(this);
	}
	liveRefresh(id) {
		fetch(`${process.env.GATSBY_ZMS_API_URL}physicians/provider/get/?id=${id}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					let physician = data[0];
					this.setState({
						status: STATUS.LOADED,
						photo: (physician.photo) ? physician.photo : '/userfiles/dr-blank.jpg'
					})
					console.log(`Live Refresh Complete - Search Card - ${physician.firstName} ${physician.lastName}`);
				}
			})
			.catch(err => console.log);
	}
	clickHandler(evt) {
		this.setState(prevState => ({
			viewAll: !prevState.viewAll,
		}));
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.photo !== prevProps.photo) {
			this.setState({
				photo: (this.props.photo) ? this.props.photo : '/userfiles/dr-blank.jpg',
			});
			this.liveRefresh(this.props.id);
		}
		if (this.state.photo !== prevState.photo) {
			this.setState({
				key: prevState.key + 1,
			});
		}
	}
	componentDidMount() {
		this.setState({
			key: STATUS.INITIAL,
			photo: (this.props.photo) ? this.props.photo : '/userfiles/dr-blank.jpg',
		});
	}
	render() {
		const { firstName, lastName, middleName, nickName, maidenName, category, title, photo, specialty, phone, fax, link, locations, specialtyExtra } = this.props;
		return (
			<div className={style.card}>
				<div className={style.photoFrame}>
					<div key={this.state.key} className={style.image}>
						<Link to={link}>
							<Background key={this.state.status} crop="faces,center" ar="1:1.2" width={800} lowQuality={false} className={style.photo} image={this.state.photo}/>
						</Link>
					</div>
				</div>
				<div className={style.content}>
					<div className={style.providerInfo}>
						<div className={style.name}><Link to={link}>{firstName}{middleName ? ` ${middleName} ` : ' '}{nickName ? ` "${nickName}" ` : ' '}{lastName}, {title}</Link></div>
						<div className={style.specialty}>{category ? `${category} - ` : ''}{specialty ? specialty.join(', ') : ''}{specialtyExtra ? parse(`<br />${specialtyExtra}`) : ''}</div>
						<div className={style.commNumbers}>
							<div className={style.number}>{phone && <Icon className={style.icon} icon="phone" />} {phone ? <Link external={true} href={`tel:+1${phone.replace(/[_-]/g, "")}`}>{phone}</Link> : ''}</div>
							<div className={style.number}>{fax && <Icon className={style.icon} icon="fax" />} {fax ? fax : ''}</div>
						</div>
					</div>
					<div className={style.locations}>
						<p className={style.multipleLocations}><em>{locations && locations.length > 1 ? 'Appointments available at multiple locations.' : ''}</em></p>
						{locations && <p onClick={this.clickHandler} className={style.viewAll}>{(!this.state.viewAll) ? (locations && locations.length > 1 ? 'View All Locations' : 'View Hours') : 'View Less'}</p>}
						{locations && locations.map((location, index) => (
							<div key={index} className={[style.location, ((index > 0) ? ((this.state.viewAll) ? style.show : style.hide) : ''), ((this.state.viewAll) ? style.showHours : '')].join(' ')}>
								<div className={style.locRow}>
									<div className={style.map}>
										<Link to={location.uri}>
											<Background className={style.mapFPO} remoteImage={true} image={`https://maps.googleapis.com/maps/api/staticmap?markers=size:small|${location.address.replace(/ /g,"+")}+${location.city.replace(/ /g,"+")},${location.state}+${location.zip}&zoom=15&size=100x100&key=${process.env.GOOGLE_MAPS_API_KEY}`} />
										</Link>
									</div>
									<div className={style.locInfo}>
										<p className={style.locName}><Link to={location.uri}>{location.name}</Link></p>
										<p className={style.locAddress}>{location.address}<br />{location.floor ? parse(`${location.floor}<br />`) : ''}{location.city}, {location.state} {location.zip}</p>
									</div>
								</div>
								{Object.keys(location.hours).length > 0 && <div className={style.hours}>
									<p className={style.hoursTitle}>Hours:</p>
									<div className={style.hoursList}>
										{Object.keys(location.hours).map((key, index) => (
											<p key={index} className={style.dayHours}>{key}: {location.hours[key]}</p>
										))}
									</div>
									<p className={location.hoursNotes ? style.hoursNotes : 'hide'}>{location.hoursNotes}</p>
								</div>}
							</div>
						))}
					</div>
				</div>
			</div>
		)
	}
}

export default SearchCard;