import React, { Component } from "react"
import { graphql } from 'gatsby'
// import $ from 'jquery';
// import { Router } from "@reach/router"
import Layout from 'components/layout/basic';
import MastheadInterior from 'components/masthead/interior'
import PatientEducationController from 'controllers/blog/patienteducation';
import ProviderSearchController from 'controllers/providers/search';
import SEO from 'shared/seo';

export default class ProvidersPage extends Component {
	constructor(props) {
		super(props);
		const { location } = props;
		let specialty = '';
		let locationID = '';
		if (location.state) {
			if (location.state.specialty) {
				specialty = location.state.specialty;
			}
			if (location.state.locationID) {
				locationID = location.state.locationID;
			}
		}
		if (location.search && location.search !== '') {
			const urlSpecialty = new URLSearchParams(location.search).get('specialty');
			if (urlSpecialty) {
				specialty = urlSpecialty;
			}
		}
		this.state = {
			specialty: specialty,
			location: locationID,
		}
	}

	render() {
		return (
			<>
				<Layout>
					<MastheadInterior title="Provider Search" intro="Find a new provider to meet your healthcare needs or call to schedule an appointment with your existing Heritage Medical Associates provider. Search by keyword or filter by specialty, location, gender, and more." />
					<hr />
					<ProviderSearchController specialty={this.state.specialty} locationID={this.state.location} />
					<hr />
					<PatientEducationController />
					<SEO />
				</Layout>
			</>
		)
	}
}